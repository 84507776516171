.mobile-winners .title {
  color: black;
}

.mobile-tabs {
  display: none;
}

.mobile-tabs p {
  border-radius: 15px 15px 0 0;
}

.nav-overlay-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  border: none;
  background-color: rgba(0, 0, 0, 0.6);
}

.nav-overlay-wrapper .nav-spotlight {
  overflow-y: auto;
  position: relative;
  background-color: white;
  top: 5%;
  left: 5%;
  width: 89%;
  height: 90%;
}

.nav-overlay-wrapper .nav-spotlight .close-nav-overlay {
  position: absolute;
  right: 2%;
  top: 2%;
  z-index: 9;
  width: 50px;
  height: 50px;
}

.nav-overlay-wrapper .nav-spotlight .close-nav-overlay img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.nav-overlay-wrapper .nav-spotlight .close-nav-overlay img:hover {
  -webkit-filter: invert(61%) sepia(6%) saturate(5583%) hue-rotate(324deg) brightness(89%) contrast(90%);
          filter: invert(61%) sepia(6%) saturate(5583%) hue-rotate(324deg) brightness(89%) contrast(90%);
}

.nav-overlay-wrapper .nav-spotlight .spotlight-info {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: url("../img-src/landing/giveaways-bg.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  display: none;
}

.nav-overlay-wrapper .nav-spotlight .spotlight-info h2 {
  text-transform: uppercase;
  margin-bottom: 5%;
  font-size: 3rem;
  font-family: LR2;
  text-align: center;
}

.nav-overlay-wrapper .nav-spotlight .spotlight-info p {
  max-width: 500px;
  width: 80%;
}

.nav-overlay-wrapper .nav-spotlight::-webkit-scrollbar {
  width: 0.6em;
}

.nav-overlay-wrapper .nav-spotlight::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.nav-overlay-wrapper .nav-spotlight::-webkit-scrollbar-thumb {
  background-color: #155473;
  outline: 1px solid slategrey;
}

.winners {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 29% !important;
  border-radius: 15px 0 0 15px !important;
}

.winners .title {
  width: 80%;
  font-size: 25px;
  text-align: center;
  margin: 0;
}

.winners ul {
  border-radius: 15px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.winners ul li {
  font-size: 2.2vh;
  width: 85%;
  list-style-type: decimal;
  list-style-position: inside;
  padding: 1% 8%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.winners ul li:nth-child(even) {
  background: #f7f2ec;
}

.aud-far {
  background-color: white;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.aud-far .next {
  width: 75px;
  position: absolute;
  bottom: 27%;
  left: 47%;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-transform: rotate3d(2, 2.2, -4.8, 100deg);
          transform: rotate3d(2, 2.2, -4.8, 100deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
}

.aud-far .next:hover {
  background-color: #00589a;
}

.aud-far .aud-far-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aud-far .nav-prestream {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 10%;
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.aud-far .nav-prestream div {
  width: 10%;
  color: white;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
  height: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.aud-far .nav-prestream div img {
  height: 80%;
}

.aud-far .nav-prestream div p {
  margin: 0;
  font-size: 2vh;
}

.aud-far .nav-prestream div:first-child {
  margin-right: auto;
}

.aud-far .nav-prestream div:not(:last-child) img:hover {
  -webkit-filter: invert(1);
          filter: invert(1);
  cursor: pointer;
}

.aud-far .nav-prestream div:nth-last-child(2) {
  -ms-flex-preferred-size: 10%;
      flex-basis: 10%;
  margin-left: auto;
}

.aud-far .nav-prestream div:nth-last-child(2) p {
  margin-right: 0 !important;
}

.fadeOut {
  -webkit-animation: fadeOut 2s   1;
          animation: fadeOut 2s   1;
}

.fadeIn {
  -webkit-animation: fadeIn 2s   1;
          animation: fadeIn 2s   1;
}

.ripple {
  -webkit-animation: ripple 2s   infinite;
          animation: ripple 2s   infinite;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideOpenRight {
  from {
    width: 0;
    overflow: hidden;
  }
  to {
    width: 100%;
  }
}

@keyframes slideOpenRight {
  from {
    width: 0;
    overflow: hidden;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes slideCloseRight {
  from {
    width: 100%;
  }
  to {
    width: 0;
    overflow: hidden;
  }
}

@keyframes slideCloseRight {
  from {
    width: 100%;
  }
  to {
    width: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0rem rgba(0, 88, 154, 0.2), 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2);
            box-shadow: 0 0 0 0rem rgba(0, 88, 154, 0.2), 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2), 0 0 0 8rem rgba(0, 88, 154, 0);
            box-shadow: 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2), 0 0 0 8rem rgba(0, 88, 154, 0);
  }
}

@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0rem rgba(0, 88, 154, 0.2), 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2);
            box-shadow: 0 0 0 0rem rgba(0, 88, 154, 0.2), 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2), 0 0 0 8rem rgba(0, 88, 154, 0);
            box-shadow: 0 0 0 1rem rgba(0, 88, 154, 0.2), 0 0 0 2rem rgba(0, 88, 154, 0.2), 0 0 0 5rem rgba(0, 88, 154, 0.2), 0 0 0 8rem rgba(0, 88, 154, 0);
  }
}

.iframe-form {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 8;
  border: none;
  display: none;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  background: rgba(255, 255, 255, 0.6);
}

.countdown {
  background: #76859c;
  position: absolute;
  width: 18%;
  height: 23%;
  top: 39%;
  left: 41.25%;
  -webkit-box-shadow: inset 0 0 10px #000000;
          box-shadow: inset 0 0 10px #000000;
}

.countdown .countdown-timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 90%;
  height: 100%;
}

.countdown .countdown-timer .countdown-timer-circle {
  width: 8em;
  margin: auto;
  height: 8em;
  border: 3px solid #155473;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  color: white;
  font-family: 'LL2';
  font-size: 0.4rem;
}

.countdown .countdown-timer .countdown-timer-circle p {
  margin: 0;
  opacity: 0.8;
}

.countdown .countdown-timer .countdown-timer-circle p:first-child {
  font-size: 4em;
}

.countdown .countdown-timer .countdown-timer-circle p:last-child {
  font-size: 1.3em;
}

.countdown .timers {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.countdown .timers .time {
  font-size: 40px;
}

.chat {
  position: relative;
}

.chat .chat-overlay {
  position: absolute;
  z-index: 2;
  height: 110%;
  width: 100%;
  background: white;
  border-radius: 0 15px 15px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5%;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
}

.chat .chat-overlay p {
  font-size: 1em;
}

.chat .chat-overlay p:nth-child(2) {
  font-size: 0.8em;
  margin-top: 0;
}

.chat .chat-overlay .icon-preview {
  width: 50px;
  height: 50px;
  background-color: rgba(50, 170, 169, 0.6);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2rem;
  margin-bottom: 5%;
}

.chat .chat-overlay input {
  padding: 15px;
  width: 80%;
}

.chat .chat-overlay button {
  margin-top: 5%;
}

.chat .chat-context-menu {
  background-color: #F7F2EC;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  padding: 5px;
  z-index: 6;
  width: 75px;
}

.chat .chat-context-menu ul {
  width: 100%;
}

.chat .chat-context-menu ul li {
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.chat .chat-context-menu ul li:hover {
  background-color: #32AAA9;
}

.chat .chat-context-menu .block-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(21, 84, 115, 0.7);
}

.chat .chat-context-menu .block-confirmation p {
  color: white;
  font-weight: bold;
  font-size: 2rem;
  max-width: 80%;
  text-align: center;
}

.chat .chat-context-menu .block-confirmation .row {
  min-width: 40%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.chat .chat-context-menu .block-confirmation button {
  padding: 0.8rem 3rem;
}

.chat .header {
  height: 15%;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.chat .header .line1 {
  font-size: 0.8em;
  margin: 0;
  padding-top: 3%;
  text-transform: uppercase;
}

.chat .header .line2 {
  font-size: 0.6em;
  margin: 0;
  padding-bottom: 5%;
}

.chat .messages {
  width: 100%;
  height: 69%;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat .messages .msg-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 50px;
  margin-bottom: 5%;
  position: relative;
}

.chat .messages .msg-item .img-btn {
  width: 30px;
  position: absolute;
  right: 0;
  top: 0;
}

.chat .messages .msg-item .img-btn img {
  width: 100%;
}

.chat .messages .msg-item p {
  margin: 0;
}

.chat .messages .msg-item .icon {
  width: 30px;
  height: 30px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  font-size: 0.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: bold;
  margin-left: 2%;
}

.chat .messages .msg-item .msg-content {
  margin-left: 3%;
  max-width: 80%;
}

.chat .messages .msg-item .msg-content .modifier {
  font-size: 0.2em;
}

.chat .messages .msg-item .msg-content .msg-text-wrapper {
  width: 100%;
  padding: 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px 10px 10px 0;
}

.chat .messages .msg-item .msg-content .msg-text-wrapper .sender-name {
  font-size: 0.25em;
  font-weight: bold;
  font-family: LT2;
}

.chat .messages .msg-item .msg-content .msg-text-wrapper .msg-text {
  font-size: 0.3em;
}

.chat .my-msg {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
  margin-right: 5%;
}

.chat .messages .msg-item:nth-child(n) .msg-content .msg-text-wrapper {
  background-color: rgba(50, 170, 169, 0.6);
}

.chat .messages .msg-item:nth-child(n) .icon {
  background-color: rgba(50, 170, 169, 0.6);
}

.chat .messages::-webkit-scrollbar {
  width: 0.6em;
}

.chat .messages::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.chat .messages::-webkit-scrollbar-thumb {
  background-color: #155473;
  outline: 1px solid slategrey;
}

.chat .send-msg {
  width: 100%;
  height: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #F7F2EC;
  border-radius: 0 0 15px 15px;
}

.chat .send-msg input {
  width: 80%;
  height: 40%;
  border-radius: 10px;
  border: 2px solid #155473;
  padding-left: 3%;
  font-family: LL2;
}

.chat .send-msg button img {
  height: 25px;
}

.chat .send-msg button:hover {
  -webkit-filter: hue-rotate(252deg);
          filter: hue-rotate(252deg);
}

.chat .send-msg .emoji-menu {
  background-color: rgba(21, 84, 115, 0.6);
  border-radius: 15px;
  padding: 5px;
  position: absolute;
  top: -100%;
  right: 10%;
}

.chat .send-msg .emoji-menu ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.chat .send-msg .emoji-menu ul .emoji-item {
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.chat .send-msg .emoji-menu ul .emoji-item:hover {
  background-color: red;
}

.feedbackform-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: 100%;
  background-image: url("../img-src/auditorium/feedback-bg.png");
  background-size: cover;
  background-position: right;
}

.feedbackform-wrapper .current-question-wrapper {
  width: 85%;
  max-width: 600px;
  height: 60%;
  min-height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.feedbackform-wrapper .current-question-wrapper p {
  font-size: 2rem;
  font-weight: bold;
}

.feedbackform-wrapper .current-question-wrapper p span {
  color: #D87152;
}

.feedbackform-wrapper .current-question-wrapper ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 5%;
}

.feedbackform-wrapper .current-question-wrapper ul li {
  width: 80% !important;
}

.feedbackform-wrapper .current-question-wrapper ul .rating-ans-container {
  width: 50px !important;
  height: 50px !important;
  padding: auto;
  background: transparent !important;
  border: 4px solid rgba(21, 84, 115, 0.6);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.feedbackform-wrapper .current-question-wrapper ul .rating-ans-container:hover {
  background: rgba(21, 84, 115, 0.6) !important;
  color: white;
}

.feedbackform-wrapper .current-question-wrapper ul .rating-ans-container-selected {
  background: rgba(21, 84, 115, 0.6) !important;
  color: white;
}

.feedbackform-wrapper .current-question-wrapper ul textarea {
  width: 60%;
  height: 5rem;
  font-family: LL2;
  padding: 15px;
  border: 1px solid #155473;
  resize: none;
}

.aud-no-event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #F7F2EC;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
}

.aud-no-event img {
  max-width: 500px;
  width: 60%;
}

.aud-no-event p {
  font-size: 30px;
  text-align: center;
}

.mobile-filler {
  display: none;
}

@media screen and (max-width: 1200px) {
  .countdown .timers {
    -webkit-transform: scale(0.4) !important;
            transform: scale(0.4) !important;
  }
  .aud-far .next {
    left: 45%;
  }
}

@media screen and (max-width: 1000px) {
  .countdown .timers {
    -webkit-transform: scale(0.3) !important;
            transform: scale(0.3) !important;
  }
}

@media screen and (max-width: 800px) {
  .expand-btn {
    display: none !important;
  }
  .mobile-filler {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 12vh;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .mobile-filler img {
    height: 60%;
  }
  .mobile-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    background: #000;
    color: white;
    position: relative;
    top: 0;
    height: 30px;
    font-size: 15px;
  }
  .mobile-tabs p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 40%;
    height: 100%;
    background-color: #00589a;
    padding: 0 20px;
    margin: 0;
    text-align: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .mobile-tabs .active {
    background-color: white;
    color: black;
  }
  .chat .mobile-winners {
    position: absolute;
    z-index: 2;
    top: 37vh;
    height: 51vh !important;
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100% !important;
    -webkit-box-shadow: 0 5px 3px 1px grey;
            box-shadow: 0 5px 3px 1px grey;
    background-color: white;
    height: 100%;
    width: 29%;
  }
  .chat .mobile-winners .title {
    width: 100%;
    padding-top: 5%;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }
  .chat .mobile-winners ul {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
  .chat .mobile-winners ul li {
    font-size: 2.2vh;
    width: 85%;
    list-style-type: decimal;
    list-style-position: inside;
    padding: 1% 8%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .chat .mobile-winners ul li:nth-child(even) {
    background: #f7f2ec;
  }
  .chat .send-msg {
    height: 20%;
  }
  .spotlight-info h2 {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 500px) {
  .aud-far {
    background-image: url("../img-src/aud-far.jpg");
    background-size: cover;
    background-position: center;
  }
  .countdown {
    width: 88%;
    left: 7.25%;
    height: 26%;
    top: 37%;
  }
  .timers {
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
  }
  .aud-far-bg {
    visibility: hidden;
  }
  .next {
    left: 40% !important;
  }
  .chat {
    visibility: visible !important;
  }
  .mobile-tabs {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .emoji-menu {
    top: -150% !important;
  }
}

@media screen and (max-height: 500px) {
  .expand-btn {
    display: none;
  }
  .nav-expanded a li img {
    width: 100%;
    max-width: 50%;
  }
  .nav-expanded a li span {
    display: block;
  }
  .nav-expanded ul {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .nav-expanded a:first-child {
    position: absolute;
    top: 5%;
  }
  .nav-expanded a:last-child {
    position: absolute;
    bottom: 5%;
  }
  .emoji-menu {
    top: -140% !important;
  }
  .mobile-tabs {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .chat .messages {
    height: 69%;
  }
  .chat .mobile-winners {
    height: 105% !important;
    border-radius: 0 0 15px 15px;
  }
  .chat .mobile-winners ul li {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=Auditorium.css.map */